import {useCallback, useEffect, useRef, useState, useMemo} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import DesktopMainNavigation from '@/components/brochureV2/section/Header/DesktopMainNavigation';
import Hamburger from '@/components/brochureV2/section/Header/Hamburger';
import MobileMainNavigation from '@/components/brochureV2/section/Header/MobileMainNavigation';
import GradientDefs from '@/components/brochureV2/section/Header/svg/GradientDefs';
import type {HeaderProps} from '@/components/brochureV2/section/Header/types';
import type {SecondaryNavigationType} from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import SecondaryNavigation from '@/components/base/sections/Header/modules/SecondaryNavigation/SecondaryNavigation';
import {HeaderBackgroundColorScheme, ScrollDirection} from '@/enums';
import useIntersectionObserver from '@/hooks/useIntersectionObserver';
import {useKeyPress} from '@/hooks/useKeyPress';
import {useMediaQuery} from '@/hooks/useMediaQuery';
import {useScrollDirection} from '@/hooks/useScrollDirection';
import {useScrollThresholds} from '@/hooks/useScrollThresholds';
import {useTranslations} from '@/hooks/useTranslations';
import type {I18NOptions, I18nRequestContext} from '@/types';
import {useExperiment} from '@/hooks/useExperiment';
import {EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24} from '@/experiments';
import {useShowHeaderPdfDownload} from '@/components/brochureV2/hooks/useShowHeaderPdfDownload';
import useDataLayer from '@/hooks/useDataLayer';

import DesktopCtaLinklist from './DesktopCtaLinklist';
import MobileCtaLinklist from './MobileCtaLinklist';
import {
  headerContainer2Styles,
  headerContainerStyles,
  headerStyles,
  primaryNavigationListStyles,
  secondaryNavigationContainerStyles,
} from './styles';
import ShopifyLogo from './svg/ShopifyLogoClient';
import {getSolutionsNav} from './config/solutions/getSolutionsNav';
import {getResourcesNav} from './config/resources/getResourcesNav';
import {getWhatsNewNav} from './config/whatsNew/getWhatsNewNav';

export {HeaderProps};

interface DefaultMainNavArgs {
  translations: I18NOptions;
  variant?: string;
  pathname?: string;
  isDownloadDocumentCta?: boolean;
  isMobile?: boolean | null;
}

export const defaultMainNav = ({
  translations: {t, localizePath, site},
  variant,
  pathname,
  isDownloadDocumentCta,
  isMobile,
}: DefaultMainNavArgs) => {
  const gaEvent = 'Main Nav';
  const i18nRequestContext: I18nRequestContext = {
    t,
    site,
  };

  const navItems: (
    | ReturnType<typeof getSolutionsNav>
    | ReturnType<typeof getResourcesNav>
    | any
  )[] = [
    getSolutionsNav(i18nRequestContext),
    {
      content: t('nav:pricing'),
      url: localizePath('/pricing'),
      data: {
        gaEvent,
        gaAction: 'Pricing Nav',
      },
    },
    getResourcesNav(i18nRequestContext),
  ];

  if (
    site.locale === 'en' &&
    pathname === '/' &&
    variant === 'enterprise_cta'
  ) {
    navItems.push({
      content: t('nav:shopifyPlus'),
      url: localizePath('/enterprise'),
    });
  }

  if (isDownloadDocumentCta && isMobile) {
    navItems.push({
      content: t('global:globalNav.headerPdfCta'),
      url: t('global:globalNav.headerPdfCtaLink'),
      isExternal: true,
    });
  }

  if (site.features.includes('editions') || site.languageCode === 'en') {
    const whatsNewItems: any = getWhatsNewNav(i18nRequestContext);
    if (whatsNewItems) {
      navItems.push(whatsNewItems);
    }
  }

  return navItems;
};

export default function Header({
  CustomDesktopCtaLinklist,
  CustomMobileCtaLinklist,
  CustomMobileMenuCtaLinklist,
  mainNavFn,
  secondaryNavFn,
  background,
  className,
  logo: logoProp,
  navigationSearch = null,
  pathname,
  mainNavData,
  secondaryNavData = null,
}: HeaderProps) {
  const translations = useTranslations();
  const variant = useExperiment(EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24);
  const data = useDataLayer();
  const isDownloadDocumentCta = useShowHeaderPdfDownload(data.pageTopic);
  const isMobile = useMediaQuery('(max-width: 1119px)');

  const mainNav = useMemo(() => {
    if (mainNavFn) return mainNavFn(translations);
    if (mainNavData) return mainNavData;
    return defaultMainNav({
      translations,
      variant,
      pathname,
      isDownloadDocumentCta,
      isMobile,
    });
  }, [
    mainNavData,
    mainNavFn,
    translations,
    variant,
    pathname,
    isDownloadDocumentCta,
    isMobile,
  ]);

  const secondaryNav = useMemo(() => {
    if (secondaryNavFn)
      return {
        ...secondaryNavFn(translations),
        moreText: translations.t('nav:more'),
      } as SecondaryNavigationType;
    if (secondaryNavData) return secondaryNavData;
  }, [secondaryNavFn, translations, secondaryNavData]);
  const hasSecondaryNav =
    (secondaryNav?.links && secondaryNav.links.length > 0) ?? false;
  const backgroundColorScheme =
    background || HeaderBackgroundColorScheme.TransparentLight;
  const darkBackground =
    backgroundColorScheme === HeaderBackgroundColorScheme.Black ||
    backgroundColorScheme === HeaderBackgroundColorScheme.TransparentDark;

  const [isPrimaryNavigationInViewport, setIsPrimaryNavigationInViewport] =
    useState(true);

  const [
    activeDesktopMainNavigationIndex,
    setActiveDesktopMainNavigationIndex,
  ] = useState(-1);
  const [activeMobileMainNavigationIndex, setActiveMobileMainNavigationIndex] =
    useState(-1);
  const [isFixed, setIsFixed] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const mainHeaderRef = useRef<HTMLDivElement>(null);

  const mainHeaderInnerRef = useRef<HTMLDivElement>(null);
  const primaryNavigationOuterContainerRef = useRef<HTMLDivElement>(null);
  const wasMobileRef = useRef<boolean | null>(null);

  const scrollDirection = useScrollDirection(hasSecondaryNav);
  const scrollThreshold = useScrollThresholds([0, 1, 71, 141]);

  // The menu should close when Escape is pressed
  useKeyPress('Escape', () => {
    setIsMobileMenuOpen(false);
    setActiveDesktopMainNavigationIndex(-1);
    setActiveMobileMainNavigationIndex(-1);
  });

  const memoizedHandleIntersectCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsPrimaryNavigationInViewport(true);
        } else {
          setIsPrimaryNavigationInViewport(false);
        }
      });
    },
    [setIsPrimaryNavigationInViewport],
  );

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  useIntersectionObserver(
    primaryNavigationOuterContainerRef,
    memoizedHandleIntersectCallback,
    options,
  );

  useEffect(() => {
    if (scrollThreshold === 0) {
      setIsFixed(false);
    } else if (hasSecondaryNav && !isPrimaryNavigationInViewport) {
      setIsFixed(true);
    } else if (!hasSecondaryNav && scrollThreshold > 0) {
      setIsFixed(true);
    }
  }, [scrollThreshold, hasSecondaryNav, isPrimaryNavigationInViewport]);

  // Once scrolled the background color of the header changes if initially transparent
  useEffect(() => {
    if (scrollThreshold > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [scrollThreshold]);

  // When transitioning between mobile and desktop breakpoints, the menu should be closed
  useEffect(() => {
    if (isMobile === null) return;

    if (isMobile && !wasMobileRef.current) {
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    } else if (!isMobile && wasMobileRef.current) {
      setIsMobileMenuOpen(false);
      setActiveDesktopMainNavigationIndex(-1);
      setActiveMobileMainNavigationIndex(-1);
    }

    wasMobileRef.current = isMobile;
  }, [isMobile]);

  // *************************
  // **  Default Components **
  // *************************
  const logo = logoProp || <ShopifyLogo darkBackground={darkBackground} />;
  const desktopCtaLinklist =
    CustomDesktopCtaLinklist === undefined ? (
      <DesktopCtaLinklist darkBackground={darkBackground} />
    ) : (
      CustomDesktopCtaLinklist
    );
  const mobileCtaLinklist =
    CustomMobileCtaLinklist === undefined ? (
      <MobileCtaLinklist darkBackground={darkBackground} />
    ) : (
      CustomMobileCtaLinklist
    );

  const hasHamburger =
    mainNav.length > 0 || CustomMobileMenuCtaLinklist !== null;

  return (
    <header
      ref={mainHeaderRef}
      className={twMerge(headerStyles({isFixed, hasSecondaryNav}), className)}
      data-component-name="global-nav"
      data-viewable-component
      data-has-secondary-navigation={hasSecondaryNav}
    >
      <div
        ref={mainHeaderInnerRef}
        className={twMerge(
          headerContainerStyles({
            backgroundColorScheme,
            hasSecondaryNav,
            isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
            isFixed,
            isMobileMenuOpen,
            isScrolled,
            isThresholdPassed: scrollThreshold < 72,
            scrollDirection,
          }),
        )}
      >
        <div
          className={twMerge(
            headerContainer2Styles({
              backgroundColorScheme,
              hasSecondaryNav,
              isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
              isFixed,
              isMobileMenuOpen,
            }),
          )}
        >
          <div
            ref={primaryNavigationOuterContainerRef}
            className="h-global-header container flex items-center"
          >
            {logo}
            <DesktopMainNavigation
              mainNav={mainNav.filter(
                (mainNavItem) =>
                  mainNavItem.data?.gaAction !== 'JP Download Document',
              )}
              openIndex={activeDesktopMainNavigationIndex}
              setOpenIndex={setActiveDesktopMainNavigationIndex}
              background={backgroundColorScheme}
            />
            <div className="ml-auto">
              {Boolean(desktopCtaLinklist) && (
                <ul className="nav:flex hidden items-center">
                  {desktopCtaLinklist}
                </ul>
              )}
              <ul
                className={twMerge(primaryNavigationListStyles({hasHamburger}))}
              >
                {mobileCtaLinklist}
                {hasHamburger && (
                  <li>
                    <Hamburger
                      background={backgroundColorScheme}
                      isActive={isMobileMenuOpen}
                      onToggle={() => {
                        setIsMobileMenuOpen(!isMobileMenuOpen);
                        setActiveDesktopMainNavigationIndex(-1);
                        setActiveMobileMainNavigationIndex(-1);
                      }}
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {hasSecondaryNav && (
          <div
            className={twMerge(
              secondaryNavigationContainerStyles({
                backgroundColorScheme,
                hasSecondaryNav,
                isDesktopMenuOpen: activeDesktopMainNavigationIndex >= 0,
                isFixed,
                isMobileMenuOpen,
              }),
            )}
          >
            <div className="h-global-header container flex items-center">
              <SecondaryNavigation
                secondaryNav={secondaryNav}
                customDesktopCtaLinklist={CustomDesktopCtaLinklist}
                mode={darkBackground ? 'dark' : 'light'}
                showGlobals={
                  isScrolled &&
                  isFixed &&
                  scrollDirection === ScrollDirection.Down
                }
                navigationSearch={navigationSearch}
              />
            </div>
          </div>
        )}
      </div>
      <MobileMainNavigation
        logo={logo as JSX.Element}
        mobileCtaLinklist={mobileCtaLinklist}
        CustomMobileMenuCtaLinklist={CustomMobileMenuCtaLinklist}
        openIndex={activeMobileMainNavigationIndex}
        setOpenIndex={setActiveMobileMainNavigationIndex}
        isMobileMenuOpen={isMobileMenuOpen}
        background={backgroundColorScheme}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveDesktopMainNavigationIndex={
          setActiveDesktopMainNavigationIndex
        }
        setActiveMobileMainNavigationIndex={setActiveMobileMainNavigationIndex}
        mainNav={mainNav}
      />
      <GradientDefs />
    </header>
  );
}
