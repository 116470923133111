import {
  linkpopUrl,
  businessNameGeneratorPath,
  blogPath,
  isToolsEnabled,
} from '@/hooks/navigation/utils';
import type {I18nRequestContext} from '@/types';
import {localizePathBySite} from '@/utils/site';

import {MAIN_NAV_GA_EVENT} from '../constants';

export function getToolsNav({t, site}: I18nRequestContext) {
  const qrUrl = () => {
    switch (site.locale) {
      case 'es-ES':
        return '/es/tools/qr-code-generator';
      case 'fr-CA':
      case 'fr-BE':
        return '/fr/tools/qr-code-generator';
      case 'en-DK':
      case 'en-NO':
      case 'en-ID':
      case 'en-PH':
      case 'en-ZA':
      case 'en-MY':
      case 'en-HK':
      case 'da':
      case 'sv':
        return '/tools/qr-code-generator';
      default:
        return localizePathBySite('/tools/qr-code-generator', site);
    }
  };

  const shouldShowLogomaker = [
    'nl',
    'nl-BE',
    'da',
    'sv',
    'ko',
    'vi',
    'zh-HK',
    'zh-TW',
  ].includes(site.locale);

  const logoMakerPath = () => {
    if (shouldShowLogomaker || site.languageCode === 'en') {
      return '/tools/logo-maker';
    }
    return localizePathBySite('/tools/logo-maker', site);
  };

  const navItems = [];

  if (isToolsEnabled(site)) {
    navItems.push({
      content: t('nav:businessNameGenerator'),
      url: businessNameGeneratorPath(site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Business name generator',
      },
    });
  }

  navItems.push({
    content: t('nav:logoMaker'),
    url: logoMakerPath(),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Logo maker',
    },
  });

  navItems.push({
    content: t('nav:stockPhotography'),
    url: 'https://www.shopify.com/stock-photos',
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Stock photography',
    },
  });

  if (site.features.includes('blog')) {
    navItems.push({
      content: t('nav:businessPlanTemplate'),
      url: blogPath('/business-plan-template', site),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'Business plan template',
      },
    });
  }

  navItems.push({
    content: t('nav:linkInBioTool'),
    url: linkpopUrl(site),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Link in bio tool',
    },
  });

  if (isToolsEnabled(site) && site.locale !== 'zh-CN') {
    navItems.push({
      content: t('nav:qrCodeGenerator'),
      url: qrUrl(),
      data: {
        gaEvent: MAIN_NAV_GA_EVENT,
        gaAction: 'QR code generator',
      },
    });
  }

  return {
    content: t('nav:essentialTools'),
    data: {
      gaEvent: MAIN_NAV_GA_EVENT,
      gaAction: 'Essential tools Nav',
    },
    columnStart: 10,
    columnSpan: 3,
    aside: true,
    icon: 'essentialTools',
    navItems,
  };
}
